import React, { useEffect, useState } from 'react';
import { Button } from '@mui/material';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Slider from '@mui/material/Slider';

import { generateNiceBG } from './NiceBackground';
import { useDebounce } from './CustomHooks';
import ImgBuzzer from './Images/buzzMe.png';
import ImgAlcolo from './Images/alcolo.svg';
import ImgSpaceCheap from './Images/spaceCheap.svg';
import ImgDrinkWar from './Images/drinkwar.svg';
import ImgSpy from './Images/spy.svg';
import ImgRe7 from './Images/re7.svg';
import ImgRe8 from './Images/re8.svg';
import ImgLoupGarou from './Images/loupgarou.svg';
import ImgListIt from './Images/listIt.svg';
import ImgRIP from './Images/RIP.svg';
import ImgMaps from './Images/map.svg';
import AdBattle from './Images/AdBattle.png';
import SharedQuest from './Images/sharedQuest.png';
import ImgDrinkIt from './Images/drinkIt.svg';
import ImgWallOfFame from './Images/wallOfFame.svg';
import './App.less';

const SITES = [
  {
    name: 'R.I.P',
    desc: `Je voulais ici rendre hommage à tous ces projets qui n'ont pas pu voir complètement le jour. Ces projets tombés au champs d'honneur. Des projets forts, parfois trop ambitieux, parfois trop inutiles, parfois tout simplement oubliés. Pour en citer que quelques uns et éventuellement me déclencher l'envie de résuciter l'un d'eux un de ces jours : ChallengeMe, IDRA, DrinkIt, Numberz, Clicker, ... `,
    url: 'https://www.youtube.com/watch?v=tkoGcSB74sU',
    img: ImgRIP,
    txtButton: 'Rendre hommage',
  },
  {
    name: 'Alcolo',
    desc: `Tout premier projet ayant vraiment vu le jour. Réalisation "simpliste" d'un jeu à boire. Des règles farfelues, des gages, des mini-jeux, ... le tout pour s'enivrer entre amis... SANTÉ !`,
    url: 'https://alcolo.idontcare.fr',
    img: ImgAlcolo,
    txtButton: 'Jouer',
  },
  {
    name: 'Drink War',
    date: '09/05/2019',
    desc: `À la suite d'Alcolo, Drink War était sensé être un jeu à boire impliquant chaque joueur avec son téléphone. Il s'est plutôt transformer en jeu de challenges / Quizz pour se défier entre amis. À tester ! ;) Premier projet nécessitant un serveur.`,
    url: 'https://drinkwar.idontcare.fr',
    img: ImgDrinkWar,
    txtButton: 'Jouer',
  },
  {
    name: 'Loup Garou',
    date: '03/08/2020',
    desc: `Tentative de numérisation du fameux jeu des Loups Garou de Thiercelieux. Développement non poussé jusqu'au bout et comporte donc quelques oublis... Mais projet intéressant `,
    url: 'https://loup-garou.idontcare.fr',
    img: ImgLoupGarou,
    txtButton: 'Jouer',
  },
  {
    name: 'Re7',
    date: '27/11/2020',
    desc: `Après trop d'appels à ma mère pour obtenir les recettes de ses bons petits plats, je décide de les répertorier sur un site ! Je lui laisse même l'accès pour en ajouter par elle-même. À vos fourneaux, ... Miam !`,
    url: 'https://re7.idontcare.fr',
    img: ImgRe7,
    txtButton: 'Voir',
  },
  {
    name: 'Spy',
    date: '28/12/2020',
    desc: `Première utilisation de React Native. C'est à dire que c'est une application mobile et non un site internet. Petit jeu où chaque joueur reçois le téléphone pour prendre connaissance d'un lieu. Les joueurs discutent ensuite ensemble pour tenter de démasquer le(s) espion(s) qui ne savent pas de quel lieu on parle.`,
    url: 'https://downloads.idontcare.fr/Spy%20v1.1.apk',
    img: ImgSpy,
    txtButton: 'Télécharger',
  },
  {
    name: 'BuzzMe',
    date: '16/02/2021',
    desc: 'Petit utilitaire pour éviter la folie lors de blind test ou de quizz à la maison. Chaque joueur a son buzzer comme sur un plateau de télé et plus question de se battre pour savoir qui a buzzé en premier.',
    url: 'https://buzzme.idontcare.fr',
    img: ImgBuzzer,
    txtButton: 'Voir',
  },
  {
    name: 'SpaceCheap',
    date: '25/02/2021',
    desc: `Inspiré par le jeu de plateau "Magic Maze", chaque joueur utilise son smartphone comme une manette pour aider un astronaute à survivre lors de son voyage dans son vaisseau rafistolé. Jeu coopératif qui demandera une entente parfaite dans les niveaux les plus difficiles. Dispose même d'un système de création de map`,
    url: 'https://spaceCheap.idontcare.fr',
    img: ImgSpaceCheap,
    txtButton: 'Jouer',
  },
  {
    name: 'List it',
    date: '17/05/2021',
    desc: `Mini jeu où les participants doivent énumérer un certain nombre d'objets, métiers, accessoires,... Dans les 9 secondes imparties ! Première application sortie sur le PlayStore, retour au React Native et première mise en place de pub pour une maigre tentative de rémunération`,
    url: 'https://play.google.com/store/apps/details?id=fr.bundle.identifier',
    img: ImgListIt,
    txtButton: 'Jouer',
  },
  {
    name: 'Maps',
    date: '01/05/2022',
    desc: `Mise en place d'une idée qui me trottait dans la tête depuis bien longtemps (bien avant même l'avènement de Pokemon GO) : un genre de RPG où le personnage principal n'est autre que le joueur lui-même grâce à la géolocalisation. Choix d'une Web App (ReactJS) discutable mais assumé pour un accès à une plus vaste majorité de gens (... enfin amis vu le traffic). Projet plus sous la forme d'un POC que vraiment abouti ! Ce qui explique son nom des plus... recherché... ! (Jeu pour smartphone)`,
    url: 'https://maps.idontcare.fr',
    img: ImgMaps,
    txtButton: 'Jouer',
  },
  {
    name: 'Ad Battle: an Ad-venture',
    date: '06/12/2022',
    desc: `Tentative d'utilisation du React Native pour faire un mini tower defense. Le thème est inspiré par l'exaspération des -trop nombreuses- pubs sur certaines applications. Seul problème, les détections de clicks est capricieuse et la durée de vie est très courte. Dommage mais très sympa à développer`,
    url: 'https://play.google.com/store/apps/details?id=fr.idontcare.adbattle',
    img: AdBattle,
    txtButton: 'Jouer',
  },
  {
    name: 'Shared Quest',
    date: '27/11/2023',
    desc: `Jeu de Coopération à 4 contre des vagues de monstres qui essayent de nous tuer. Le but est d'en venir à bout en s'échangeant les différents objet que l'on peut crafter selon son rôle ! (ex: La sorcière est la seule à pouvoir faire des potions de soins). Jeu créé en React Native et le serveur en Node.JS`,
    url: 'https://play.google.com/store/apps/details?id=fr.idontcare.sharedquest',
    img: SharedQuest,
    txtButton: 'Jouer',
  },
  {
    name: 'Re8',
    date: '03/01/2024',
    desc: `Mise à niveau de l'ancien site de recettes. Mis au goût du jour en React 18 + Node.JS + Express + mongoose (MongoDB). Utilisation de viteJS + vitePWA pour la gestion du serviceWorker qui permet de faire fonctionner la consultation hors-ligne du site et d'envoyer des notifications push !`,
    url: 'https://re8.idontcare.fr',
    img: ImgRe8,
    txtButton: 'Voir',
  },
  {
    name: 'Drink it !',
    date: '25/01/2024',
    desc: `Mini-jeu rapide et efficace : Multijoueur jusqu'a 6 sur le même téléphone. Test la rapidité des joueurs et l'esprit de compétition fait le reste !`,
    url: 'https://play.google.com/store/apps/details?id=fr.idontcare.drinkit',
    img: ImgDrinkIt,
    txtButton: 'Jouer',
  },
  {
    name: 'Wall of Fame',
    date: '16/02/2024',
    desc: `Permet de créer un mur éphémère où les participants à un évênement peuvent partager des photos pour mettre en valeur (ou pas) une personne en particulier ou simplement partager des photos du moment sur un projecteur, TV, ...`,
    url: 'https://walloffame.idontcare.fr',
    img: ImgWallOfFame,
    txtButton: 'Voir',
  },
];
const initBalls = 30;

const App = () => {
  const [nbBalls, setNbBalls] = useState(initBalls);
  const [expanded, setExpanded] = useState('');

  const debouncedBalls = useDebounce(nbBalls, 300);

  useEffect(() => {
    generateNiceBG(nbBalls);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedBalls]);

  const changeToSite = (siteName) => (e, isExpanded) => {
    setExpanded(isExpanded ? siteName : false);
  };

  const goToWebsite = (url) => (e) => {
    e.preventDefault();
    window.open(url, '_blank').focus();
  };

  const reversed = SITES.toReversed();
  return (
    <div className='App'>
      <h1 className='title'>HUB</h1>
      <div className='sitesWrapper custom-scrollbar'>
        {reversed.map((s) => {
          const isExpanded = s.name === expanded;
          const centeredIfExpanded = isExpanded
            ? {
                justifyContent: 'center',
              }
            : {};
          return (
            <Accordion expanded={isExpanded} className='accordion' key={s.name} onChange={changeToSite(s.name)}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls='panel1a-content' id='panel1a-header'>
                <div className='accordionHeader' style={centeredIfExpanded}>
                  {!isExpanded && s.img && <img alt='logoHeader' className='logoHeader' src={s.img} />}
                  <div>{s.name}</div>
                  {!isExpanded && s.name !== 'R.I.P' && s.txtButton && (
                    <Button className='linkButton' variant='contained' color='secondary' onClick={goToWebsite(s.url)}>
                      {s.txtButton}
                    </Button>
                  )}
                  {isExpanded && <div className='date'>{s.date}</div>}
                </div>
              </AccordionSummary>
              <AccordionDetails className='details'>
                <div className='imageWrapper'>{s.img && <img alt='logo' className='image' src={s.img} />}</div>
                <div className='desc'>
                  <div>{s.desc}</div>
                  {s.txtButton && (
                    <Button className='descButton' variant='contained' color='primary' onClick={goToWebsite(s.url)}>
                      {s.txtButton}
                    </Button>
                  )}
                </div>
              </AccordionDetails>
            </Accordion>
          );
        })}
      </div>
      <div className='sliderWrapper'>
        <Slider
          aria-label='Temperature'
          defaultValue={initBalls}
          valueLabelDisplay='off'
          onChange={(_, value) => setNbBalls(value)}
          step={10}
          size='small'
          min={0}
          max={300}
        />
      </div>
    </div>
  );
};

export default App;
