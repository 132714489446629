import { createTheme } from '@mui/material/styles';
const palette = {
  mode: 'dark',
  primary: {
    main: '#eebc19',
  },
  secondary: {
    main: '#0074aa',
  },
};
const components = {
  MuiButton: {
    variants: [
      {
        props: { variant: 'red' },
        style: {
          border: `1px solid red`,
          color: 'red',
        },
      },
    ],
  },
};
const themeName = 'MyTheme';
export default createTheme({ palette, themeName, components });
