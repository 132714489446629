import React from 'react';
import ReactDOM from 'react-dom';
import { ThemeProvider, StyledEngineProvider } from '@mui/material/styles';

import App from './App';
// import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import MyTheme from './Theme';
import reportWebVitals from './reportWebVitals';
// import InstallDrawer from './Components/InstallDrawer/InstallDrawer';
// import LangageSelector from './Translation/LangageSelector';

ReactDOM.render(
  <React.StrictMode>
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={MyTheme}>
        <App />
        {/* <InstallDrawer /> */}

        {/* Comment it if no translation involved / move it if it needs to be conditionnaly present */}
        {/* <LangageSelector /> */}
      </ThemeProvider>
    </StyledEngineProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
// serviceWorkerRegistration.register();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
